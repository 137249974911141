
import { mapState, mapGetters } from "vuex"
import searchService from "@/services/search.service"
const debounce = require("lodash.debounce")
import { mdiSend, mdiMapMarker, mdiMagnify, mdiChevronLeft } from "@mdi/js"
import OabatTextFieldSearchIcon from "@/components/base/design/OabatTextFieldSearchIcon"

export default {
    name: "LocationSearch2",
    components: { OabatTextFieldSearchIcon },
    props: {
        small: {
            type: Boolean,
            default: false,
        },
        useSearchStore: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        /** Default value to be set in the inputfield */
        value: {
            type: String,
            default: "",
        },
        back: {
            type: Boolean,
            default: false,
        },
        prefix: {
            type: String,
            default: "Gib",
        },
        icon: {
            type: String,
            default: mdiMagnify,
        },
        /** Attaches the v-menu to the combobox to enforce opening it to the bottom. Results in scrolling behaviour on parent component */
        attach: {
            type: Boolean,
            default: false,
        },
        /** Disables location hints */
        noHint: {
            type: Boolean,
            default: false,
        },
        /** Remvoes nearby selection */
        noNearby: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mdiMapMarker: mdiMapMarker,
            mdiSend: mdiSend,
            snackbar: false,
            loading: false,
            items: [],
            search: null,
            select: null,
            autocomplete: null,
            hasFocus: false,
            debouncedSearch: debounce(this.performSearch, 300, {
                leading: false,
                trailing: true,
            }),
            attachElement: undefined,
            isRequestingLocation: false,
            hasNoGeoLocation: false,
            noResult: false,
        }
    },
    computed: {
        ...mapState("search", ["location_search"]),
        ...mapGetters("search", { getRoute: "route" }),
        logoWidth() {
            return this.isSearchActive ? 60 : 120
        },
        prependIcon() {
            if (this.back) return mdiChevronLeft
            return ""
        },
        itemsLocation() {
            if ((!this.search || this.items.length === 0) && !this.noNearby) {
                return [
                    { name: "In meiner Region suchen", slug: "nearby", id: 0 },
                ]
            }
            return this.items.slice(0, 5)
        },
    },
    watch: {
        search(val) {
            if (val) {
                this.noResult = false
                this.debouncedSearch(val)
            }
        },
        value(val) {
            this.search = val
        },
    },
    mounted() {
        this.attachElement = this.attach ? this.$refs.location?.$el : undefined
    },
    methods: {
        async performSearch(search) {
            this.loading = true

            const result = await this.$axios
                .$post("/api/search/autocomplete_city", { search: search })
                .catch((e) => console.warn(e))
            this.items = result.items
            if (result.items.length == 0) this.noResult = true
            this.loading = false
        },
        clear() {
            this.items = []
            this.search = null
            this.select = null
        },
        setFocus(value) {
            this.hasFocus = value
            this.$emit("focus", value)
            if (!value) {
                this.$refs["location"]?.blur()
            }
        },
        getCurrentLocation() {
            if (navigator.geolocation) {
                let hintDelay = setTimeout(
                    () => (this.isRequestingLocation = true),
                    100
                )
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const location = {
                            longitude: position.coords.longitude,
                            latitude: position.coords.latitude,
                        }
                        clearTimeout(hintDelay)
                        this.isRequestingLocation = false
                        let route = {
                            slug: "nearby",
                            channel: "oabat",
                            type: "default",
                            filters: location,
                        }
                        this.setFocus(false)
                        this.$emit("route", route)
                        this.$emit("input", location)
                    },
                    () => {
                        this.isRequestingLocation = false
                        clearTimeout(hintDelay)
                        this.snackbar = true
                        this.setFocus(false)
                    },
                    { enableHighAccuracy: true, maximumAge: 10000 }
                )
            } else {
                this.hasNoGeoLocation = true
            }
        },
        async setCity(input) {
            if (!input || !input.slug) return
            if (input.slug === "nearby") {
                this.getCurrentLocation()
                return
            }

            const cityData = await searchService.getLocationFromSlug(input.id)

            const route = {
                name: cityData.name,
                slug: cityData.slug,
                filters: {
                    longitude: cityData.longitude,
                    latitude: cityData.latitude,
                },
                type: "default",
                channel: "oabat",
            }
            this.setFocus(false)
            this.$emit("input", cityData)
            this.$emit("route", route)
        },
        goBack() {
            this.setFocus(false)
            this.$emit("back")
        },
        focus() {
            setTimeout(() => {
                this.setFocus(true)
                this.$refs.location.onFocus()
                this.$refs.location.activateMenu()
            }, 300)
        },
    },
}
