
import Button from "@/components/base/Button"
import Card from "@/components/base/card/Card"
import LocationSearch2 from "@/components/search/LocationSearch2"
import BrowserLocation from "@/components/search/BrowserLocation"

import { mdiChevronUp } from "@mdi/js"
import searchService from "@/services/search.service"
import locationService from "@/services/location.service"

export default {
    name: "ChatInputRegion",
    components: { Card, Button, LocationSearch2, BrowserLocation },
    /**
     * click: click on the input
     * focus: input is focused
     * input: input value change
     * send: event to send the message, including raw data object
     */
    emits: ["click", "focus", "send", "input"],
    props: {
        /** input is disabled (vuetify prop)  */
        disabled: {
            type: Boolean,
            default: false,
        },
        /** selected value (v-model)  */
        value: {
            type: [String, Number],
            default: "",
        },
        /** label of the input field (vuetify prop)  */
        label: {
            type: String,
            default: "Text",
        },
    },
    data() {
        return {
            mdiChevronUp,
            showOptions: false,
            showTextField: true,
            options: [],
            popularCities: [],
            selectedValue: "",
            isLocationSearchOpen: false,
        }
    },
    async mounted() {
        this.popularCities = await searchService
            .getCities()
            .catch((e) => console.warn(e))
    },
    methods: {
        openMenu(withFocus = false) {
            this.showOptions = true
            this.showTextField = false
            if (withFocus === true) {
                this.$nextTick(() => this.$refs.locationSearch.focus())
            }
        },
        closeMenu(e) {
            if (this.isLocationSearchOpen) return
            this.$emit("focus", e)
            this.showOptions = false
            setTimeout(() => (this.showTextField = true), 300)
        },
        async selectCoordinates(e) {
            this.selectedValue = e

            const loc = await locationService.getAddressForCoordinates(
                e.latitude,
                e.longitude
            )
            this.$emit("input", loc.city)
            this.$emit("send", loc)
            this.closeMenu()
        },
        async selectCity(e) {
            if (!e.latitude || !e.longitude) {
                e = await searchService.getLocationFromSlug(e.id)
            }
            if (!e.name) {
                this.selectCoordinates(e)
                return
            }
            this.selectedValue = e.name
            this.$emit("input", e.name)
            this.$emit("send", e)
            this.closeMenu()
        },
    },
}
