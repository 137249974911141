
import Button from "@/components/base/Button"
import { mdiCompass, mdiMapMarker } from "@mdi/js"

export default {
    name: "BrowserLocation",
    components: { Button },
    inputs: ["input"],
    props: {
        /** Disables location hints */
        noHint: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        mdiCompass,
        mdiMapMarker,
        isRequestingLocation: false,
        hasNoGeoLocation: false,
        showSnackbar: false,
    }),
    methods: {
        getCurrentLocation() {
            if (navigator.geolocation) {
                let hintDelay = setTimeout(
                    () => (this.isRequestingLocation = true),
                    100
                )
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        clearTimeout(hintDelay)
                        this.isRequestingLocation = false
                        this.$emit("input", {
                            longitude: position.coords.longitude,
                            latitude: position.coords.latitude,
                        })
                    },
                    () => {
                        this.isRequestingLocation = false
                        clearTimeout(hintDelay)
                        this.showSnackbar = true
                    },
                    { enableHighAccuracy: true, maximumAge: 10000 }
                )
            } else {
                this.$emit("noLocation")
                this.hasNoGeoLocation = true
            }
        },
    },
}
